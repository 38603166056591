<template>
  <div style=";margin: -15px -15px;background-color: #f0f2f5;">
    <!-- 日期查询框 -->
    <div class="datePicker">
      <div class="date">
        建立日期：
        <a-range-picker id="date" v-model="time" @change="dateChange"  style="width: 200px" />
      </div>
      <div class="date">
        标签：
        <a-select
            v-model="searchId"
            mode="multiple"
            style="width: 220px"
            placeholder="请选择"
            @change="handleChange"
            :maxTagCount="1"
            :maxTagTextLength="5"
        >
          <a-select-option v-for="i in tagList" :key="i.id">
            {{ i.labelContent}}
          </a-select-option>
        </a-select>
      </div>
      <a-button type="primary" @click="searchBtn">查询</a-button>
      <a-button style="margin-left: 15px" @click="returnBtn">重置</a-button>
    </div>

    <!-- table -->
    <div class="table">
      <a-table :scroll="{ x: '1700px' }" :columns="tableColumns" :pagination="false" :data-source="tableData"
               :row-key="(record) => record.id">
        <span slot="count" slot-scope="text,record">
           <a @click="routerBtn(record)">{{record.count}}</a>
        </span>
        <span slot="tag" slot-scope="text,record">
           <a-tag v-for="(list,index) in record.caseLabelList" style="margin: 5px" v-show="list!==null" :key="index" color="#108ee9">
               {{list.labelContent}}
           </a-tag>
        </span>
      </a-table>
      <!-- 分页 -->
      <div style="background-color: #fff;width:100%;height: 100px;">
        <a-pagination style="float: right;margin-top: 25px;" show-quick-jumper show-size-changer :page-size.sync="pagination.pageSize"
                      @change="pageChange" @showSizeChange="pageChange"  v-model="pagination.current" :total=pagination.total
        />
      </div>
    </div>

    <div style="bottom:20px;right:60px;display: flex;justify-content: flex-end;background: white;position: absolute">
      <a-button style="margin-left: 15px" @click="()=>{this.$router.back()}">返回上一层</a-button>
    </div>

    <a-drawer
        title="病程"
        placement="right"
        width="50%"
        :visible="visible"
        @close="()=>{this.visible=false;this.caseData=[]}"
    >
      <div class="drawer" v-for="(list,index) in caseData" :key="index">
        <div class="case-index">
          <span style="margin-left: 30%;margin-top: -10%">{{list.index}}</span>
        </div>
        <h3>
          病程信息
        </h3>
        <div style="display: flex;justify-content: space-between;margin-bottom: 20px">
          <div>
            类型：{{ list.content.type }}
          </div>
          <div>
            就诊日期：{{ list.content.treatment_date }}
          </div>
          <div>
            年龄：{{ list.content.age }}
          </div>
          <div>
            住院号：{{list.content.admission_no}}
          </div>
          <div>
            住院科室：{{list.content.admission_office}}
          </div>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <div >诊断结果：</div>
          <div style="flex: 1">
            <div>
              {{list.content.diagnose}}
            </div>
            <div v-if="list.content.diagnose_img.length!==0" style="display: flex;flex-wrap: wrap;margin-top: 20px">
              <img v-for="img in list.content.diagnose_img" :src="img" @click="imgBtn(img)" alt="" style="cursor: pointer;width: 100px;height: 120px;margin:0 10px 10px 0">
            </div>
          </div>
        </div>

        <div style="display: flex;margin-bottom: 20px">
          <div >主诉：</div>
          <div style="flex: 1">
            <div>
              {{list.content.chief_complaint}}
            </div>
            <div v-if="list.content.chief_img.length!==0" style="display: flex;flex-wrap: wrap;margin-top: 20px">
              <img v-for="img in list.content.chief_img" :src="img" @click="imgBtn(img)" alt="" style="cursor: pointer;width: 100px;height: 120px;margin:0 10px 10px 0">
            </div>
          </div>

        </div>

        <div style="display: flex;margin-bottom: 20px">
          <div >现病史：</div>
          <div style="flex: 1">
            <div>
              {{list.content.hpi}}
            </div>
            <div v-if="list.content.hpi_img.length!==0" style="display: flex;flex-wrap: wrap;margin-top: 20px">
              <img v-for="img in list.content.hpi_img" :src="img" @click="imgBtn(img)" alt="" style="cursor: pointer;width: 100px;height: 120px;margin:0 10px 10px 0">
            </div>
          </div>

        </div>

        <div style="display: flex;margin-bottom: 20px">
          <div >既往史：</div>
          <div style="flex: 1">
            <div> {{list.content.pmh}}</div>
            <div v-if="list.content.pmh_img.length!==0" style="display: flex;flex-wrap: wrap;margin-top: 20px">
              <img v-for="img in list.content.pmh_img" :src="img" @click="imgBtn(img)" alt="" style="cursor: pointer;width: 100px;height: 120px;margin:0 10px 10px 0">
            </div>
          </div>

        </div>

        <h3 style="margin-top: 20px">
          附件信息
        </h3>
        <div  style="display: flex;flex-wrap: wrap">
          <img v-for="img in list.content.images" :src="img" @click="imgBtn(img)" alt="" style="cursor: pointer;width: 100px;height: 120px;margin:0 10px 10px 0">
        </div>
      </div>

    </a-drawer>


    <a-modal :visible="img_show" :footer="null" @cancel="()=>{this.img_show=false}">
      <img  :src="img" alt="" style="width: 100%;height: 100%;">
    </a-modal>

  </div>
</template>

<script>
import {getCase_patient, getTags} from "@/service/caseClip_api";

export default {
  name: "caseClip_tags",
  data(){
    return{
      img:'',
      img_show:false,
      visible:false,
      startTime:'',
      endTime:'',
      time:[],
      tagList:[],
      userId:'',
      searchId:[],
      tableData: [],  //用于列表数据
      searchName: '' , //传输入框的数据
      pagination: {   //用于分页
        total: 0,
        current: 1,
        pageSize: 10,
      },
      tableColumns:[
        {
          title: '患者姓名',
          dataIndex: 'name',
          width: '10%'
        },
        {
          title: '性别',
          align:'center',
          dataIndex: 'sex',
          customRender: (value, row, index) => {
            if (value === 1) {
              value = '男';
            }else {
              value='女'
            }
            return value;
          },
          width: '5%'
        },
        {
          title: '出生日期',
          dataIndex: 'birthday',
          width: '10%'
        },
        {
          title: '门诊号',
          dataIndex: 'mrnNo',
          align:'center',
          width: '8%'
        },
        {
          title: '手机号',
          dataIndex: 'tel',
          width: '10%',
        },
        {
          title: '标签',

          scopedSlots: { customRender: 'tag' },
        },
        {
          title: '病程数量',
          scopedSlots: { customRender: 'count' },
          width: '8%',
          align:'center'
        },
        {
          title: '病程类型',
          dataIndex: 'type',

          width: '10%',
        },
        {
          title: '建立日期',
          dataIndex: 'createdTime',
        },
      ],
      caseData:[],
    }
  },
  mounted() {
    this.getPatient()
    this.getTag()
  },
  methods:{
    async getPatient(){
      const data={
        id:this.$route.query.id,
        startTime:this.startTime,
        endTime:this.endTime,
        labelId:this.searchId.toString(),
        pageNum: this.pagination.current,
        pageSize:  this.pagination.pageSize
      }
      const info =await getCase_patient(data)
      if (info.code===0){
        info.data.list.forEach(value => {
          value.type = value.type && Array.from(new Set(value.type.split('/'))).join("/");
          if (value.hasOwnProperty('caseDetailList')&&value.caseDetailList.length!==0) {

            value.caseDetailList.forEach(value2 => {
              value2.content = JSON.parse(value2.content);
            });
          }
        });
        console.log( info.data.list)
        this.pagination.total=info.data.total;
        this.tableData=info.data.list;
      }
    },
    async getTag(){
      const info = await getTags(this.$route.query.id)
      if (info.code===0){
        this.tagList=info.data.filter(item => {
          return item.memberId === this.$route.query.id;
        })
      }
    },

    returnBtn() {
      this.time=[]
      this.searchName=''
      this.startTime=''
      this.endTime=''
      this.searchId=[]
      this.pagination.current=1;
      this.getPatient()
    },
    searchBtn(){
      this.pagination.current=1;
      this.getPatient()
    },
    pageChange(page_no, page_size){
      this.pagination.current = page_no;
      this.pagination.pageSize = page_size;
      this.getPatient()
    },

    routerBtn(row){
      if (row.count!==0){
        this.caseData=row.caseDetailList.reverse()
        this.caseData.forEach((value,index) => {
          value.index=this.caseData.length-index;
        })
        console.log(this.caseData)
        this.visible=true
      }

    },
    imgBtn(img){
      this.img=img
      this.img_show=true
    },
    handleChange(value) {
      this.searchId=value
    },
    dateChange(date, dateString){
      this.startTime = dateString[0]
      this.endTime = dateString[1]
      this.time=dateString
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-modal-body{
  padding: 0;
}
.drawer{
  min-height: 300px;
  width: 100%;
  margin-bottom: 20px;
  background: white;
  padding: 50px 50px 50px 100px ;
  position: relative;
}
::v-deep .ant-drawer-body{
  background: #ECECEC;
  height: auto;
  min-height: 100%;
}
.case-index{
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
  background: #DFF0FF;
  border-radius: 0 0 100px 0;
  display: flex;
  align-items: center;
  font-size: x-large;
}


.datePicker{
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.date {
  /* margin: auto; */
  margin: 0 20px 0 10px;
  font-size: 15px;
  font-weight: 500;
  color:#272727;
  width: 30%;
}
.table{

  padding: 20px;
  background-color: #fff;
}

</style>